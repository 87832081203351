<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title d-inline-block text-truncate"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :title="dataList.formData.identifier"
                id="exampleModalLabel">
                {{ dataList.formData.identifier }}
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <form @submit.prevent="submitForm">
            <div class="modal-body">

                <div class="row">
                    <div class="col-12 mb-3">
                        <div v-html="dataList.formData.explanation"></div>
                    </div>
                    <div v-if="status === 'Assigned' || status === 'Submitted'" class="col-12 mb-3">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                                   v-model="dataList.isApproved"
                                   :value="true">
                            <label class="form-check-label" for="inlineRadio1">Approved</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input " type="radio" name="inlineRadioOptions" id="inlineRadio2"
                                   v-model="dataList.isApproved"
                                   :value="false">
                            <label class="form-check-label" for="inlineRadio2">Reject</label>
                        </div>
                    </div>
                    <div v-if="!dataList.isApproved && (status === 'Assigned' || status === 'Submitted')"
                         class="col-12 mb-3">
                        <label class="form-control-label">Rational</label>
                        <textarea v-model="dataList.formData.rational"
                                  class="form-control form-control-sm"
                                  placeholder="Rationale why you need Reject">
                        </textarea>
                    </div>
                </div>
            </div>
            <div v-if="status === 'Assigned' || status === 'Submitted'" class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="submit" class="btn btn-primary">Save</button>
            </div>
        </form>
    </div>
</template>

<script>
import {reactive, watch} from "vue";

export default {
    name: "ContentItemReviewModal",
    props: {
        formData: {
            type: Object,
            required: true,
        },
        status: {
            type: String,
            required: true
        }
    },
    emits: ['submitForm'],
    setup(props, {emit}) {
        let dataList = reactive({
            isApproved: true,
            formData: {
                is_reject: false,
                identifier: '',
                explanation: '',
                rational: ''
            }
        })
        /** watch properties **/
        watch(() => props.formData, (value) => {
            if (value) {
                dataList.formData = {...dataList.formData, ...value};
                dataList.isApproved = !dataList.formData.is_reject;
            }
        }, {immediate: true})

        /** watch properties end **/

        function submitForm() {
            dataList.formData.is_reject = !dataList.isApproved;
            emit('submitForm', dataList.formData)
        }

        return {
            dataList,
            submitForm
        }
    }
}
</script>
