<template>
  <section>
    <div class="row">
      <div
        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3"
      >
        <div class="table-box p-3 mb-3">
          <div
            class="
              table-title
              d-flex
              flex-xxl-row
              flex-xl-row
              flex-lg-row
              flex-md-row
              flex-sm-column
              flex-column
              mb-3
            "
          >
            <div class="flex-grow-1 my-auto">
              <h2 class="mb-xxl-0 mb-xl-0 mb-lg-0 mb-md-0 mb-sm-3 mb-3 pb-2">
                Template Items
              </h2>
            </div>
          </div>
          <div class="col-sm-offset-2 col-sm-10 mb-2">
            <button type="button" @click.prevent="save" class="btn btn-green">back</button>
            <button type="button" @click.prevent="approveAll" class="btn btn-green ms-2" v-if="dataList.contentItems.length > 0 && dataList.rowData.canEdit && !dataList.hasNullExplanation">Approve All</button>
          </div>
          <div
            v-if="getTemplateContentItems.length > 0"
            class="table-responsive"
            style="max-height: 400px"
          >

            <nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home"
                            type="button" role="tab" aria-controls="nav-home" aria-selected="true">All</button>
                    <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile"
                            type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Empty</button>
                </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                    <table class="table table-striped table-hover">
                        <thead class="table-header">
                            <tr>
                                <th width="10%">SL</th>
                                <th width="50%">Identifier</th>
                                <th v-if="dataList.rowData.canEdit" width="30%">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in getTemplateContentItems" :key="index">
                                <td>{{ index + 1 }}</td>
                                <td>
                                    {{ item.identifier }}
                                </td>
                                <td v-if="dataList.rowData.canEdit">
                                    <router-link
                                    v-if="
                                        dataList.rowData.status === 'Assigned' ||
                                        dataList.rowData.status === 'Draft'
                                    "
                                    :to="`/library/content-library/${item.id}`"
                                    >
                                    {{ id ? "Update" : "Add" }}
                                    </router-link>
                                    <button
                                    v-else
                                    type="button"
                                    @click.prevent="modalOpen(item, index)"
                                    style="color: #ffffff"
                                    :class="[
                                        'btn me-2',
                                        typeof item.is_reject === 'undefined'
                                        ? 'btn-info'
                                        : !item.is_reject
                                        ? 'btn-green'
                                        : 'btn-danger',
                                    ]"
                                    >
                                    show
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                    <table class="table table-striped table-hover">
                        <thead class="table-header">
                            <tr>
                            <th width="10%">SL</th>
                            <th width="50%">Identifier</th>
                            <th v-if="dataList.rowData.canEdit" width="30%">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in getTemplateContentItems.filter((getTemplateContentItems) => getTemplateContentItems.explanation === null)" :key="index">
                                <td>{{ index + 1 }}</td>
                                <td>
                                    {{ item.identifier }}
                                </td>
                                <td v-if="dataList.rowData.canEdit">
                                    <router-link
                                    v-if="
                                        dataList.rowData.status === 'Assigned' ||
                                        dataList.rowData.status === 'Draft'
                                    "
                                    :to="`/library/content-library/${item.id}`"
                                    >
                                    {{ id ? "Update" : "Add" }}
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            
          </div>
        </div>
      </div>
    </div>
  </section>
  
  <!-- Modal -->
  <div class="modal fade" id="showModal" tabindex="-1" aria-labelledby="ReviewModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" style="height: auto">
      <content-item-review-modal
        v-if="dataList.isModalActive"
        :status="dataList.rowData.status"
        :form-data="dataList.selectedContentItem"
        @submit-form="submitReviewContentItem"
      />
    </div>
  </div>
  <!-- /Modal -->

</template>

<script>
import HelperFunction from "@/common/helpers";
import { computed, onMounted, reactive, watch, inject } from "vue";
import ContentItemReviewModal from "@/views/auth/library/template/ContentItemReviewModal";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "TemplateItems",
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  components: { ContentItemReviewModal },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const { selectedCompany, data, getAllData, openModal } = HelperFunction();
    let dataList = reactive({
      isModalActive: false,
      selectedContentItem: {},
      selectedContentItemIndex: null,
      contentItems: {},
      rowData: {},
      hasNullExplanation: false
    });
    const swal = inject('$swal');

    onMounted(() => {
      $("#showModal").on("hidden.bs.modal", (e) => {
        dataList.isModalActive = false;
        dataList.selectedContentItem = {};
        dataList.selectedContentItemIndex = null;
      });
    });

    watch(
      () => props.id,
      (value) => {
        if (value) {
          getData();
        }
      },
      { immediate: true }
    );

    const getTemplateContentItems = computed(() => {
      return store.getters.getTemplateContentItems.length > 0
        ? store.getters.getTemplateContentItems
        : dataList.contentItems;
    });

    function getData() {
      // data.setURL = `${vueConfig.Library.TemplatesEndPoint}/${props.id}/items`;
      data.setURL = `${vueConfig.Library.TemplatesEndPoint}/${props.id}`;
      let params = {
        company_id: selectedCompany.value.id ? selectedCompany.value.id : null,
      };
      getAllData(data.setURL, params).then((res) => {
        let result = res.data.data;
        if (result) {
          dataList.rowData = result;
          dataList.contentItems = result.items;

          // See any explanation is null or not for any content item
          for (const key in dataList.contentItems) {
            if (dataList.contentItems[key].explanation === null) {
              dataList.hasNullExplanation = true;
              break;
            }
          }
        }
      });
    }

    const modalOpen = (item, index) => {
      dataList.selectedContentItemIndex = index;
      dataList.selectedContentItem = item;
      dataList.isModalActive = true;
      openModal("showModal");
    };

    const submitReviewContentItem = (contentItem) => {
      dataList.contentItems[dataList.selectedContentItemIndex] = {
        ...dataList.contentItems[dataList.selectedContentItemIndex],
        ...contentItem,
        status: "Submitted",
      };

      store.dispatch("setTemplateContentItems", dataList.contentItems);
      $("#showModal").modal("hide");
    };

    const approveAll = () => {
      swal({
        title: 'Are you sure?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes Approve All!',
        cancelButtonText: 'Cancel',
        showCloseButton: true,
        showLoaderOnConfirm: true
      }).then((result) => {
        if (result.value) {
          let allContentItems = dataList.contentItems;

          for (let index = 0; index < allContentItems.length; index++) {
            dataList.contentItems[index] = {
              ...dataList.contentItems[index],
              // ...contentItem,
              status: "Submitted",
              is_reject: false,
            };

            store.dispatch("setTemplateContentItems", dataList.contentItems);
          }

          swal('Approved All', 'You have successfully approved all', 'success');
        } 
      });
    }

    const save = async () => {
      await store.dispatch("setTemplateContentItems", dataList.contentItems);
      await router.push(`/library/update-template/${props.id}`);
    };

    return {
      dataList,
      submitReviewContentItem,
      modalOpen,
      approveAll,
      save,
      getTemplateContentItems,
    };
  },
};
</script>

<style scoped>
</style>
